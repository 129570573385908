import React from "react";

const CurrentProjects = () => {
    return (
        <section className="flex flex-col items-center justify-center bg-white p-4 md:p-20 lg:p-52">
            {/* Header */}
            <div className="text-center mb-12">
                <h2 className="text-green-900 font-extrabold">My Work</h2>
                <h3 className="text-3xl font-bold text-gray-800">Insights into my work</h3>
                <p className="text-gray-600 mt-2">Curious about my daily work? Check it out in this section</p>
            </div>

            {/* Project Cards */}
            <div className="grid gap-8 md:grid-cols-3 max-w-5xl">
                {/* Project 1 */}
                <div className="relative flex">
                    <div
                        className="bg-green-900 w-full h-full absolute top-3 left-3 transform -rotate-2 rounded-3xl"></div>
                    <div className="relative bg-green-200 p-6 rounded-3xl shadow-lg flex-grow">
                        <h4 className="text-xl font-bold text-green-900">Research Project</h4>
                        <p className="mt-2 text-gray-700">How can we reduce CO2 and contribute to environmental
                            protection?</p>
                        <p className="mt-2 text-gray-700">Our project helps you to reduce your personal CO2 footprint.
                            At the same time you will receive a reward in the form of our Web3 App Token.</p>
                        <p className="mt-2 text-gray-700 italic">(Presentation coming soon)</p>
                    </div>
                </div>

                {/* Project 2 */}
                <div className="relative flex">
                    <div
                        className="bg-green-900 w-full h-full absolute top-3 left-3 transform -rotate-2 rounded-3xl"></div>
                    <div className="relative bg-green-200 p-6 rounded-3xl shadow-lg flex-grow">
                        <h4 className="text-xl font-bold text-green-900">Pantry Master</h4>
                        <p className="mt-2 text-gray-700">Your digital, artificial intelligence powered household
                            management tool.</p>
                        <p className="mt-2 text-gray-700">Check it out on App Store and Google Play</p>
                    </div>
                </div>

                {/* Project 3 */}
                <div className="relative flex">
                    <div
                        className="bg-green-900 w-full h-full absolute top-3 left-3 transform -rotate-2 rounded-3xl"></div>
                    <div className="relative bg-green-200 p-6 rounded-3xl shadow-lg flex-grow">
                        <h4 className="text-xl font-bold text-green-900">Quick Note</h4>
                        <p className="mt-2 text-gray-700">A modern mobile productivity and ToDo application, developed
                            for Android.</p>
                        <p className="mt-2 text-gray-700">Always wondered, which tasks takes the most time? Find it out
                            with Quick Note.</p>
                        <p className="mt-2 text-gray-700 italic">(Still limited to beta access)</p>
                    </div>
                </div>

            </div>
        </section>

    )
}

export default CurrentProjects;