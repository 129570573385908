import React from "react";

const LegalNotice = () => {
    return (
        <section className="flex flex-col justify-start lg:justify-center px-4 py-8 lg:px-14 lg:py-16 text-black">
            <div className="flex flex-col h-full space-y-12 lg:space-y-8">
                <div className="flex flex-row w-full justify-center text-2xl md:text-3xl lg:text-4xl font-bold">
                    Legal Notice
                </div>
                <div>
                    <div className="text-xl md:text-2xl lg:text-3xl font-semibold">
                        Contact
                    </div>
                    <div className="text-xl lg:text-2xl font-medium">
                        Maurice Woike
                    </div>
                    <div className="text-xl lg:text-2xl font-medium">
                        Willi-Blume-Weg 75
                    </div>
                    <div className="text-xl lg:text-2xl font-medium">
                        38239 Salzgitter
                    </div>
                    <div className="text-xl lg:text-2xl font-medium">
                        Tel: +49 1525 6614697
                    </div>
                    <div className="text-xl lg:text-2xl font-medium">
                        Mail: maurice.woike@gmail.com
                    </div>
                </div>
                <div>
                    <div className="text-xl md:text-2xl lg:text-3xl font-semibold">
                        Consumer dispute resolution/universal arbitration board
                    </div>
                    <div className="text-xl lg:text-2xl font-medium">
                        We are not willing or obliged to participate in dispute resolution proceedings before a
                        to participate in the consumer arbitration board.
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LegalNotice;