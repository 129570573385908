import React from "react";
import portraitIcon from "../../icons/portrait-colored-svg.svg";
import telephoneIcon from "../../icons/telephone-icon.svg";
import emailIcon from "../../icons/email-icon.svg";


const Contact = () => {
    return (
        <section className="flex flex-col items-center py-10 px-4 lg:px-12 bg-white">
            <p className="text-green-900 font-extrabold">Contact</p>
            <h2 className="text-2xl px-6 font-bold text-center lg:text-3xl">Do not hesitate to get in touch</h2>
            <p className="text-center px-6 text-gray-600 mt-2">Whether you have general or specific questions - Let's get in
                touch</p>

            <div className="flex flex-col lg:flex-row w-full items-center gap-8 mt-10">
                {/* Placeholder for image */}
                <img src={portraitIcon} alt="container icon" className="w-72 h-96 lg:w-96 lg:h-1/5 xl:translate-x-96"/>

                {/* Contact Card */}
                <div
                    className="bg-green-900 text-white pt-6 lg:pt-32 pb-6 lg:pb-32 pl-6 lg:pl-10 pr-6 lg:pr-10 rounded-3xl w-full max-w-xs lg:max-w-none lg:w-1/2 lg:ml-auto lg:mr-0 lg:translate-x-8">
                    <h3 className="text-lg lg:text-2xl font-semibold mb-1">Maurice Woike</h3>
                    <p className="text-sm font-light mb-4">Freelance Software Engineer</p>
                    <p className="text-sm lg:text-lg mb-4">
                        Based near Brunswick - Germany, but working remotely all over the world.
                    </p>
                    <p className="text-sm lg:text-lg mb-6">
                        Besides developing the actual software, I can also be a consultant. This enables you to reach
                        your goals much more efficiently.
                    </p>
                    <div className="flex flex-col gap-4">
                        <div className="flex items-center gap-2">
                            <img src={emailIcon} alt="container icon" className="w-8 h-8 lg:w-10 lg:h-10"/>
                            <a href="mailto:maurice.woike@gmail.com"
                               className="text-white underline text-sm lg:text-lg font-bold">
                                maurice.woike@gmail.com
                            </a>
                        </div>
                        <div className="flex items-center gap-2">
                            <img src={telephoneIcon} alt="container icon" className="w-8 h-8 lg:w-10 lg:h-10"/>
                            <a href="tel:+4915256614697" className="text-white underline text-sm lg:text-lg font-bold">
                                +49 1525 6614697
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    )
}

export default Contact;