import React from "react";
import {Typewriter} from "react-simple-typewriter";

const Landing = () => {

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth' // Sanftes Scrollen
        });
    };

    return (
        <section className="flex flex-col items-center justify-center min-h-screen bg-white p-4 md:p-8">
            {/* Content Box with white border */}
            <div
                className="flex flex-col justify-between bg-gradient-to-b from-green-200 to-transparent rounded-2xl w-full min-h-screen md:p-16 text-center">
                {/* Main Content */}
                <div className="flex-grow pt-20 lg:pt-40">
                    <ul className="text-3xl md:text-4xl lg:text-5xl lg:space-y-3">
                        <li className="italic text-2xl font-bold text-green-900 md:text-4xl">
                            <Typewriter
                                words={['Innovative', 'Creative', 'Dynamic']}
                                loop={false}
                                cursor
                                cursorStyle="_"
                                typeSpeed={130}
                                deleteSpeed={70}
                                delaySpeed={1000}
                            />
                        </li>

                        <li>
                            <div className="text-2xl font-bold text-green-900 md:text-4xl">
                                <div>
                                    solutions tailored for you
                                </div>
                            </div>
                        </li>
                    </ul>

                    <h1 className="text-2xl font-bold text-green-900 md:text-4xl">for you</h1>
                    <p className="mt-2 text-gray-700">build by Maurice Woike - Freelance software developer</p>

                    <button
                        className="mt-20 md:mt-6 px-6 py-2 bg-green-800 text-white rounded-lg hover:bg-green-700"
                        onClick={scrollToBottom}
                    >
                        Get in touch
                    </button>


                </div>

                {/* Scroll Icon and Hint at the Bottom */}
                <div className="flex flex-col items-center mb-4 pb-20">
                    <p className="mt-10 text-gray-700 text-sm pb-5 w-1/2 xl:w-1/6">
                        Hey there! It’s an honour to me that you came across my site. Take a few minutes and learn more
                        about me
                    </p>
                    {/* Scroll Arrows at the Bottom */}
                    <div className="flex flex-col items-center">
                        {/* Icons ohne großen Abstand */}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-8 w-8 text-gray-800 animate-bounce -mb-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 10l8 8 8-8"/>
                        </svg>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-8 w-8 text-gray-800 animate-bounce -mb-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 10l8 8 8-8"/>
                        </svg>
                    </div>
                    <p className="mt-2 text-gray-700 text-sm font-bold pulse">See more</p>
                </div>
            </div>
        </section>

    )
}

export default Landing;