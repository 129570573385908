import React from "react";
import phoneIcon from '../../icons/phone-icon.svg';
import containerIcon from '../../icons/container-icon.svg';
import dataStoreIcon from '../../icons/datastore-icon.svg';
import cloudIcon from '../../icons/cloud-icon.svg';
import ciIcon from '../../icons/ci-icon.svg';
import scrumIcon from '../../icons/scrum-icon.svg';

const Technologies = () => {
    return (
        <section className="flex flex-col items-center py-10 px-4 lg:px-0 bg-white">
            <p className="text-green-900 font-extrabold">Tools & Techniques</p>
            <h2 className="text-2xl font-bold text-center lg:text-3xl mt-1">Which tools and techniques do I use?</h2>
            <p className="text-center text-gray-600 mt-2">The result of a project depends largely on the tools used</p>

            <div className="bg-green-100 rounded-xl w-full max-w-lg lg:max-w-4xl mt-10 p-6 lg:p-10 grid grid-cols-1 lg:grid-cols-2 gap-14 lg:gap-28">
                <div className="flex items-center gap-4">
                    <img src={ciIcon} alt="container icon" className="w-12 h-12 lg:w-14 lg:h-14"/>
                    <p className="text-sm md:text-lg italic font-medium">Continuous Integration and Continuous Delivery (CI/CD)</p>
                </div>
                <div className="flex items-center gap-4">
                    <img src={phoneIcon} alt="container icon" className="w-12 h-12 lg:w-14 lg:h-14"/>
                    <p className="text-sm md:text-lg italic font-medium">Mobile Development - Native Android and Cross Platform with
                        Flutter</p>
                </div>
                <div className="flex items-center gap-4">
                    <img src={cloudIcon} alt="container icon" className="w-12 h-12 lg:w-14 lg:h-14"/>
                    <p className="text-sm md:text-lg italic font-medium">Cloud Solutions with AWS, Google Cloud or Firebase</p>
                </div>
                <div className="flex items-center gap-4">
                    <img src={dataStoreIcon} alt="container icon" className="w-12 h-12 lg:w-14 lg:h-14"/>
                    <p className="text-sm md:text-lg italic font-medium">Backend Development with Spring Boot</p>
                </div>
                <div className="flex items-center gap-4">
                    <img src={containerIcon} alt="container icon" className="w-12 h-12 lg:w-14 lg:h-14"/>
                    <p className="text-sm md:text-lg italic font-medium">Docker Containerisation and Kubernetes</p>
                </div>
                <div className="flex items-center gap-4">
                    <img src={scrumIcon} alt="container icon" className="w-12 h-12 lg:w-14 lg:h-14"/>
                    <p className="text-sm md:text-lg italic font-medium">Scrum and agile methods</p>
                </div>
            </div>
        </section>


    )
}

export default Technologies;