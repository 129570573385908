import React from "react";

const PrivacyPolicy = () => {
    return (
        <section className="flex flex-col justify-start lg:justify-center px-4 py-8 lg:px-14 lg:py-16">
            <div className="flex flex-col h-full space-y-12 lg:space-y-8">
                <div
                    className="flex flex-row w-full justify-center text-white text-2xl md:text-3xl lg:text-4xl font-bold">
                    Privacy Policy
                </div>
                <div className="text-black text-xl lg:text-2xl font-medium">
                    <h2 id="m716">Präambel</h2>
                    <p>Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer
                        personenbezogenen Daten (nachfolgend auch kurz als "Daten" bezeichnet) wir zu welchen Zwecken
                        und in welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle von uns durchgeführten
                        Verarbeitungen personenbezogener Daten, sowohl im Rahmen der Erbringung unserer Leistungen als
                        auch insbesondere auf unseren Webseiten, in mobilen Applikationen sowie innerhalb externer
                        Onlinepräsenzen, wie z. B. unserer Social-Media-Profile (nachfolgend zusammenfassend bezeichnet
                        als "Onlineangebot").</p>
                    <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>

                    <p>Stand: 17. Mai 2024</p><h2>Inhaltsübersicht</h2>
                    <ul className="index">
                        <li><a className="index-link" href="#m716">Präambel</a></li>
                        <li><a className="index-link" href="#m3">Verantwortlicher</a></li>
                        <li><a className="index-link" href="#mOverview">Übersicht der Verarbeitungen</a></li>
                        <li><a className="index-link" href="#m2427">Maßgebliche Rechtsgrundlagen</a></li>
                        <li><a className="index-link" href="#m27">Sicherheitsmaßnahmen</a></li>
                        <li><a className="index-link" href="#m25">Übermittlung von personenbezogenen Daten</a></li>
                        <li><a className="index-link" href="#m24">Internationale Datentransfers</a></li>
                        <li><a className="index-link" href="#m12">Allgemeine Informationen zur Datenspeicherung und
                            Löschung</a></li>
                        <li><a className="index-link" href="#m10">Rechte der betroffenen Personen</a></li>
                        <li><a className="index-link" href="#m317">Geschäftliche Leistungen</a></li>
                        <li><a className="index-link" href="#m13">Geschäftsprozesse und -verfahren</a></li>
                        <li><a className="index-link" href="#m605">Einsatz von Online-Plattformen zu Angebots- und
                            Vertriebszwecken</a></li>
                        <li><a className="index-link" href="#m326">Zahlungsverfahren</a></li>
                        <li><a className="index-link" href="#m225">Bereitstellung des Onlineangebots und Webhosting</a>
                        </li>
                        <li><a className="index-link" href="#m134">Einsatz von Cookies</a></li>
                        <li><a className="index-link" href="#m367">Registrierung, Anmeldung und Nutzerkonto</a></li>
                        <li><a className="index-link" href="#m182">Kontakt- und Anfrageverwaltung</a></li>
                        <li><a className="index-link" href="#m1643">Push-Nachrichten</a></li>
                        <li><a className="index-link" href="#m263">Webanalyse, Monitoring und Optimierung</a></li>
                        <li><a className="index-link" href="#m136">Präsenzen in sozialen Netzwerken (Social Media)</a>
                        </li>
                        <li><a className="index-link" href="#m328">Plug-ins und eingebettete Funktionen sowie
                            Inhalte</a></li>
                        <li><a className="index-link" href="#m15">Änderung und Aktualisierung</a></li>
                        <li><a className="index-link" href="#m42">Begriffsdefinitionen</a></li>
                    </ul>
                    <h2 id="m3">Verantwortlicher</h2><p>Maurice Woike<br/>Willi-Blume-Weg 75<br/>38239 Salzgitter</p>
                    E-Mail-Adresse: <a href="mailto:maurice.woike@gmail.com">maurice.woike@gmail.com</a>
                    Impressum: <a href="impressum.html">https://pantry-master.com/impressum.html</a>

                    <h2 id="mOverview">Übersicht der Verarbeitungen</h2><p>Die nachfolgende Übersicht fasst die Arten
                    der verarbeiteten Daten und die Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen
                    Personen.</p><h3>Arten der verarbeiteten Daten</h3>
                    <ul>
                        <li>Bestandsdaten.</li>
                        <li>Zahlungsdaten.</li>
                        <li>Kontaktdaten.</li>
                        <li>Inhaltsdaten.</li>
                        <li>Vertragsdaten.</li>
                        <li>Nutzungsdaten.</li>
                        <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
                        <li>Protokolldaten.</li>
                    </ul>
                    <h3>Kategorien betroffener Personen</h3>
                    <ul>
                        <li>Leistungsempfänger und Auftraggeber.</li>
                        <li>Interessenten.</li>
                        <li>Kommunikationspartner.</li>
                        <li>Nutzer.</li>
                        <li>Geschäfts- und Vertragspartner.</li>
                        <li>Kunden.</li>
                    </ul>
                    <h3>Zwecke der Verarbeitung</h3>
                    <ul>
                        <li>Erbringung vertraglicher Leistungen und Erfüllung vertraglicher Pflichten.</li>
                        <li>Kommunikation.</li>
                        <li>Sicherheitsmaßnahmen.</li>
                        <li>Reichweitenmessung.</li>
                        <li>Büro- und Organisationsverfahren.</li>
                        <li>Organisations- und Verwaltungsverfahren.</li>
                        <li>Feedback.</li>
                        <li>Marketing.</li>
                        <li>Profile mit nutzerbezogenen Informationen.</li>
                        <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
                        <li>Informationstechnische Infrastruktur.</li>
                        <li>Öffentlichkeitsarbeit.</li>
                        <li>Geschäftsprozesse und betriebswirtschaftliche Verfahren.</li>
                    </ul>
                    <h2 id="m2427">Maßgebliche Rechtsgrundlagen</h2><p><strong>Maßgebliche Rechtsgrundlagen nach der
                    DSGVO: </strong>Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren
                    Basis wir personenbezogene Daten verarbeiten. Bitte nehmen Sie zur Kenntnis, dass neben den
                    Regelungen der DSGVO nationale Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten
                    können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen
                    diese in der Datenschutzerklärung mit.</p>
                    <ul>
                        <li><strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)</strong> - Die betroffene Person hat
                            ihre Einwilligung in die Verarbeitung der sie betreffenden personenbezogenen Daten für einen
                            spezifischen Zweck oder mehrere bestimmte Zwecke gegeben.
                        </li>
                        <li><strong>Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b)
                            DSGVO)</strong> - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
                            Vertragspartei die betroffene Person ist, oder zur Durchführung vorvertraglicher Maßnahmen
                            erforderlich, die auf Anfrage der betroffenen Person erfolgen.
                        </li>
                        <li><strong>Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO)</strong> - Die
                            Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung erforderlich, der der
                            Verantwortliche unterliegt.
                        </li>
                        <li><strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)</strong> - die
                            Verarbeitung ist zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines
                            Dritten notwendig, vorausgesetzt, dass die Interessen, Grundrechte und Grundfreiheiten der
                            betroffenen Person, die den Schutz personenbezogener Daten verlangen, nicht überwiegen.
                        </li>
                    </ul>
                    <p><strong>Nationale Datenschutzregelungen in Deutschland: </strong>Zusätzlich zu den
                        Datenschutzregelungen der DSGVO gelten nationale Regelungen zum Datenschutz in Deutschland.
                        Hierzu gehört insbesondere das Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der
                        Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere
                        Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung, zum Widerspruchsrecht, zur
                        Verarbeitung besonderer Kategorien personenbezogener Daten, zur Verarbeitung für andere Zwecke
                        und zur Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall einschließlich
                        Profiling. Ferner können Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung
                        gelangen.</p>
                    <p><strong>Hinweis auf Geltung DSGVO und Schweizer DSG: </strong>Diese Datenschutzhinweise dienen
                        sowohl der Informationserteilung nach dem Schweizer DSG als auch nach der
                        Datenschutzgrundverordnung (DSGVO). Aus diesem Grund bitten wir Sie zu beachten, dass aufgrund
                        der breiteren räumlichen Anwendung und Verständlichkeit die Begriffe der DSGVO verwendet werden.
                        Insbesondere statt der im Schweizer DSG verwendeten Begriffe „Bearbeitung" von „Personendaten",
                        "überwiegendes Interesse" und "besonders schützenswerte Personendaten" werden die in der DSGVO
                        verwendeten Begriffe „Verarbeitung" von „personenbezogenen Daten" sowie "berechtigtes Interesse"
                        und "besondere Kategorien von Daten" verwendet. Die gesetzliche Bedeutung der Begriffe wird
                        jedoch im Rahmen der Geltung des Schweizer DSG weiterhin nach dem Schweizer DSG bestimmt.</p>

                    <h2 id="m27">Sicherheitsmaßnahmen</h2><p>Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
                    Berücksichtigung des Stands der Technik, der Implementierungskosten und der Art, des Umfangs, der
                    Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeiten
                    und des Ausmaßes der Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete technische
                    und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.</p>
                    <p>Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und
                        Verfügbarkeit von Daten durch Kontrolle des physischen und elektronischen Zugangs zu den Daten
                        als auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der Sicherung der
                        Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine
                        Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen auf die Gefährdung der
                        Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei
                        der Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend dem Prinzip des
                        Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.</p>
                    <p>Sicherung von Online-Verbindungen durch TLS-/SSL-Verschlüsselungstechnologie (HTTPS): Um die
                        Daten der Nutzer, die über unsere Online-Dienste übertragen werden, vor unerlaubten Zugriffen zu
                        schützen, setzen wir auf die TLS-/SSL-Verschlüsselungstechnologie. Secure Sockets Layer (SSL)
                        und Transport Layer Security (TLS) sind die Eckpfeiler der sicheren Datenübertragung im
                        Internet. Diese Technologien verschlüsseln die Informationen, die zwischen der Website oder App
                        und dem Browser des Nutzers (oder zwischen zwei Servern) übertragen werden, wodurch die Daten
                        vor unbefugtem Zugriff geschützt sind. TLS, als die weiterentwickelte und sicherere Version von
                        SSL, gewährleistet, dass alle Datenübertragungen den höchsten Sicherheitsstandards entsprechen.
                        Wenn eine Website durch ein SSL-/TLS-Zertifikat gesichert ist, wird dies durch die Anzeige von
                        HTTPS in der URL signalisiert. Dies dient als ein Indikator für die Nutzer, dass ihre Daten
                        sicher und verschlüsselt übertragen werden.</p>

                    <h2 id="m25">Übermittlung von personenbezogenen Daten</h2><p>Im Rahmen unserer Verarbeitung von
                    personenbezogenen Daten kommt es vor, dass diese an andere Stellen, Unternehmen, rechtlich
                    selbstständige Organisationseinheiten oder Personen übermittelt beziehungsweise ihnen gegenüber
                    offengelegt werden. Zu den Empfängern dieser Daten können z. B. mit IT-Aufgaben beauftragte
                    Dienstleister gehören oder Anbieter von Diensten und Inhalten, die in eine Website eingebunden sind.
                    In solchen Fällen beachten wir die gesetzlichen Vorgaben und schließen insbesondere entsprechende
                    Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit den Empfängern Ihrer Daten
                    ab.</p>

                    <h2 id="m24">Internationale Datentransfers</h2><p>Datenverarbeitung in Drittländern: Sofern wir
                    Daten in einem Drittland (d. h., außerhalb der Europäischen Union (EU), des Europäischen
                    Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung im Rahmen der Inanspruchnahme von Diensten
                    Dritter oder der Offenlegung bzw. Übermittlung von Daten an andere Personen, Stellen oder
                    Unternehmen stattfindet, erfolgt dies nur im Einklang mit den gesetzlichen Vorgaben. Sofern das
                    Datenschutzniveau in dem Drittland mittels eines Angemessenheitsbeschlusses anerkannt wurde (Art. 45
                    DSGVO), dient dieser als Grundlage des Datentransfers. Im Übrigen erfolgen Datentransfers nur dann,
                    wenn das Datenschutzniveau anderweitig gesichert ist, insbesondere durch Standardvertragsklauseln
                    (Art. 46 Abs. 2 lit. c) DSGVO), ausdrückliche Einwilligung oder im Fall vertraglicher oder
                    gesetzlich erforderlicher Übermittlung (Art. 49 Abs. 1 DSGVO). Im Übrigen teilen wir Ihnen die
                    Grundlagen der Drittlandübermittlung bei den einzelnen Anbietern aus dem Drittland mit, wobei die
                    Angemessenheitsbeschlüsse als Grundlagen vorrangig gelten. Informationen zu Drittlandtransfers und
                    vorliegenden Angemessenheitsbeschlüssen können dem Informationsangebot der EU-Kommission entnommen
                    werden: <a
                        href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de">https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de.</a>
                </p>
                    <p>EU-US Trans-Atlantic Data Privacy Framework: Im Rahmen des sogenannten „Data Privacy Framework"
                        (DPF) hat die EU-Kommission das Datenschutzniveau ebenfalls für bestimmte Unternehmen aus den
                        USA im Rahmen der Angemessenheitsbeschlusses vom 10.07.2023 als sicher anerkannt. Die Liste der
                        zertifizierten Unternehmen als auch weitere Informationen zu dem DPF können Sie der Website des
                        Handelsministeriums der USA unter <a href="https://www.dataprivacyframework.gov/"
                                                             >https://www.dataprivacyframework.gov/</a> (in
                        Englisch) entnehmen. Wir informieren Sie im Rahmen der Datenschutzhinweise, welche von uns
                        eingesetzten Diensteanbieter unter dem Data Privacy Framework zertifiziert sind.</p>

                    <h2 id="m12">Allgemeine Informationen zur Datenspeicherung und Löschung</h2><p>Wir löschen
                    personenbezogene Daten, die wir verarbeiten, gemäß den gesetzlichen Bestimmungen, sobald die
                    zugrundeliegenden Einwilligungen widerrufen werden oder keine weiteren rechtlichen Grundlagen für
                    die Verarbeitung bestehen. Dies betrifft Fälle, in denen der ursprüngliche Verarbeitungszweck
                    entfällt oder die Daten nicht mehr benötigt werden. Ausnahmen von dieser Regelung bestehen, wenn
                    gesetzliche Pflichten oder besondere Interessen eine längere Aufbewahrung oder Archivierung der
                    Daten erfordern.</p>
                    <p>Insbesondere müssen Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden
                        müssen oder deren Speicherung notwendig ist zur Rechtsverfolgung oder zum Schutz der Rechte
                        anderer natürlicher oder juristischer Personen, entsprechend archiviert werden.</p>
                    <p>Unsere Datenschutzhinweise enthalten zusätzliche Informationen zur Aufbewahrung und Löschung von
                        Daten, die speziell für bestimmte Verarbeitungsprozesse gelten.</p>
                    <p>Bei mehreren Angaben zur Aufbewahrungsdauer oder Löschungsfristen eines Datums, ist stets die
                        längste Frist maßgeblich.</p>
                    <p>Beginnt eine Frist nicht ausdrücklich zu einem bestimmten Datum und beträgt sie mindestens ein
                        Jahr, so startet sie automatisch am Ende des Kalenderjahres, in dem das fristauslösende Ereignis
                        eingetreten ist. Im Fall laufender Vertragsverhältnisse, in deren Rahmen Daten gespeichert
                        werden, ist das fristauslösende Ereignis der Zeitpunkt des Wirksamwerdens der Kündigung oder
                        sonstige Beendigung des Rechtsverhältnisses.</p>
                    <p>Daten, die nicht mehr für den ursprünglich vorgesehenen Zweck, sondern aufgrund gesetzlicher
                        Vorgaben oder anderer Gründe aufbewahrt werden, verarbeiten wir ausschließlich zu den Gründen,
                        die ihre Aufbewahrung rechtfertigen.</p>
                    <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
                    <ul className="m-elements">
                        <li><strong>Aufbewahrung und Löschung von Daten: </strong>Die folgenden allgemeinen Fristen
                            gelten für die Aufbewahrung und Archivierung nach deutschem Recht: <ul>
                                <li>10 Jahre - Aufbewahrungsfrist für Bücher und Aufzeichnungen, Jahresabschlüsse,
                                    Inventare, Lageberichte, Eröffnungsbilanz sowie die zu ihrem Verständnis
                                    erforderlichen Arbeitsanweisungen und sonstigen Organisationsunterlagen,
                                    Buchungsbelege und Rechnungen (§ 147 Abs. 3 i. V. m. Abs. 1 Nr. 1, 4 und 4a AO, §
                                    14b Abs. 1 UStG, § 257 Abs. 1 Nr. 1 u. 4, Abs. 4 HGB).
                                </li>
                                <li>6 Jahre - Übrige Geschäftsunterlagen: empfangene Handels- oder Geschäftsbriefe,
                                    Wiedergaben der abgesandten Handels- oder Geschäftsbriefe, sonstige Unterlagen,
                                    soweit sie für die Besteuerung von Bedeutung sind, z. B. Stundenlohnzettel,
                                    Betriebsabrechnungsbögen, Kalkulationsunterlagen, Preisauszeichnungen, aber auch
                                    Lohnabrechnungsunterlagen, soweit sie nicht bereits Buchungsbelege sind und
                                    Kassenstreifen (§ 147 Abs. 3 i. V. m. Abs. 1 Nr. 2, 3, 5 AO, § 257 Abs. 1 Nr. 2 u.
                                    3, Abs. 4 HGB).
                                </li>
                                <li>3 Jahre - Daten, die erforderlich sind, um potenzielle Gewährleistungs- und
                                    Schadensersatzansprüche oder ähnliche vertragliche Ansprüche und Rechte zu
                                    berücksichtigen sowie damit verbundene Anfragen zu bearbeiten, basierend auf
                                    früheren Geschäftserfahrungen und üblichen Branchenpraktiken, werden für die Dauer
                                    der regulären gesetzlichen Verjährungsfrist von drei Jahren gespeichert (§§ 195, 199
                                    BGB).
                                </li>
                            </ul></li>
                    </ul>
                    <h2 id="m10">Rechte der betroffenen Personen</h2><p>Rechte der betroffenen Personen aus der DSGVO:
                    Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15
                    bis 21 DSGVO ergeben:</p>
                    <ul>
                        <li><strong>Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
                            Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen
                            Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen;
                            dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Werden die Sie
                            betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie
                            das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden
                            personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das
                            Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.</strong></li>
                        <li><strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht, erteilte
                            Einwilligungen jederzeit zu widerrufen.
                        </li>
                        <li><strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung darüber zu verlangen,
                            ob betreffende Daten verarbeitet werden und auf Auskunft über diese Daten sowie auf weitere
                            Informationen und Kopie der Daten entsprechend den gesetzlichen Vorgaben.
                        </li>
                        <li><strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den gesetzlichen Vorgaben
                            das Recht, die Vervollständigung der Sie betreffenden Daten oder die Berichtigung der Sie
                            betreffenden unrichtigen Daten zu verlangen.
                        </li>
                        <li><strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong> Sie haben nach
                            Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen, dass Sie betreffende Daten
                            unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine
                            Einschränkung der Verarbeitung der Daten zu verlangen.
                        </li>
                        <li><strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie betreffende Daten,
                            die Sie uns bereitgestellt haben, nach Maßgabe der gesetzlichen Vorgaben in einem
                            strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder deren Übermittlung an
                            einen anderen Verantwortlichen zu fordern.
                        </li>
                        <li><strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben unbeschadet eines anderweitigen
                            verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer
                            Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthaltsorts,
                            ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind,
                            dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die Vorgaben der
                            DSGVO verstößt.
                        </li>
                    </ul>

                    <h2 id="m317">Geschäftliche Leistungen</h2><p>Wir verarbeiten Daten unserer Vertrags- und
                    Geschäftspartner, z. B. Kunden und Interessenten (zusammenfassend als „Vertragspartner" bezeichnet),
                    im Rahmen von vertraglichen und vergleichbaren Rechtsverhältnissen sowie damit verbundenen Maßnahmen
                    und im Hinblick auf die Kommunikation mit den Vertragspartnern (oder vorvertraglich), etwa zur
                    Beantwortung von Anfragen.</p>
                    <p>Wir verwenden diese Daten, um unsere vertraglichen Verpflichtungen zu erfüllen. Dazu gehören
                        insbesondere die Pflichten zur Erbringung der vereinbarten Leistungen, etwaige
                        Aktualisierungspflichten und Abhilfe bei Gewährleistungs- und sonstigen Leistungsstörungen.
                        Darüber hinaus verwenden wir die Daten zur Wahrung unserer Rechte und zum Zwecke der mit diesen
                        Pflichten verbundenen Verwaltungsaufgaben sowie der Unternehmensorganisation. Zudem verarbeiten
                        wir die Daten auf Grundlage unserer berechtigten Interessen sowohl an einer ordnungsgemäßen und
                        betriebswirtschaftlichen Geschäftsführung als auch an Sicherheitsmaßnahmen zum Schutz unserer
                        Vertragspartner und unseres Geschäftsbetriebs vor Missbrauch, Gefährdung ihrer Daten,
                        Geheimnisse, Informationen und Rechte (z. B. zur Beteiligung von Telekommunikations-, Transport-
                        und sonstigen Hilfsdiensten sowie Subunternehmern, Banken, Steuer- und Rechtsberatern,
                        Zahlungsdienstleistern oder Finanzbehörden). Im Rahmen des geltenden Rechts geben wir die Daten
                        von Vertragspartnern nur insoweit an Dritte weiter, als dies für die vorgenannten Zwecke oder
                        zur Erfüllung gesetzlicher Pflichten erforderlich ist. Über weitere Formen der Verarbeitung,
                        etwa zu Marketingzwecken, werden die Vertragspartner im Rahmen dieser Datenschutzerklärung
                        informiert.</p>
                    <p>Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen wir den Vertragspartnern vor
                        oder im Rahmen der Datenerhebung, z. B. in Onlineformularen, durch besondere Kennzeichnung (z.
                        B. Farben) bzw. Symbole (z. B. Sternchen o. Ä.), oder persönlich mit.</p>
                    <p>Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und vergleichbarer Pflichten, d.
                        h. grundsätzlich nach vier Jahren, es sei denn, dass die Daten in einem Kundenkonto gespeichert
                        werden, z. B., solange sie aus gesetzlichen Gründen der Archivierung aufbewahrt werden müssen
                        (etwa für Steuerzwecke im Regelfall zehn Jahre). Daten, die uns im Rahmen eines Auftrags durch
                        den Vertragspartner offengelegt wurden, löschen wir entsprechend den Vorgaben und grundsätzlich
                        nach Ende des Auftrags.</p>
                    <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. der vollständige Name,
                            Wohnadresse, Kontaktinformationen, Kundennummer, etc.); Zahlungsdaten (z. B.
                            Bankverbindungen, Rechnungen, Zahlungshistorie); Kontaktdaten (z. B. Post- und
                            E-Mail-Adressen oder Telefonnummern). Vertragsdaten (z. B. Vertragsgegenstand, Laufzeit,
                            Kundenkategorie).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Leistungsempfänger und Auftraggeber; Interessenten.
                            Geschäfts- und Vertragspartner.
                        </li>
                        <li><strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher Leistungen und Erfüllung
                            vertraglicher Pflichten; Kommunikation; Büro- und Organisationsverfahren; Organisations- und
                            Verwaltungsverfahren. Geschäftsprozesse und betriebswirtschaftliche Verfahren.
                        </li>
                        <li><strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend Angaben im Abschnitt
                            "Allgemeine Informationen zur Datenspeicherung und Löschung".
                        </li>
                        <li className=""><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
                            Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO); Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1
                            lit. c) DSGVO). Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                        </li>
                    </ul>
                    <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
                    <ul className="m-elements">
                        <li><strong>Projekt- und Entwicklungsleistungen: </strong>Wir verarbeiten die Daten unserer
                            Kunden sowie Auftraggeber (nachfolgend einheitlich als "Kunden" bezeichnet), um ihnen die
                            Auswahl, den Erwerb bzw. die Beauftragung der gewählten Leistungen oder Werke sowie
                            verbundener Tätigkeiten als auch deren Bezahlung und Zurverfügungstellung bzw. Ausführung
                            oder Erbringung zu ermöglichen.<br/><br/>Die erforderlichen Angaben sind als solche im
                            Rahmen des Auftrags-, Bestell- bzw. vergleichbaren Vertragsschlusses gekennzeichnet und
                            umfassen die zur Leistungserbringung und Abrechnung benötigten Angaben sowie
                            Kontaktinformationen, um etwaige Rücksprachen halten zu können. Soweit wir Zugang zu
                            Informationen der Endkunden, Mitarbeitern oder anderer Personen erhalten, verarbeiten wir
                            diese im Einklang mit den gesetzlichen und vertraglichen Vorgaben; <span
                                className=""><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).</span>
                        </li>
                    </ul>
                    <h2 id="m13">Geschäftsprozesse und -verfahren</h2><p>Personenbezogene Daten von Leistungsempfängern
                    und Auftraggebern – darunter Kunden, Klienten oder in speziellen Fällen Mandanten, Patienten oder
                    Geschäftspartner sowie weitere Dritte – werden im Rahmen vertraglicher sowie vergleichbarer
                    Rechtsverhältnisse und vorvertraglicher Maßnahmen wie der Anbahnung von Geschäftsbeziehungen
                    verarbeitet. Diese Datenverarbeitung unterstützt und erleichtert betriebswirtschaftliche Abläufe in
                    Bereichen wie Kundenmanagement, Vertrieb, Zahlungsverkehr, Buchhaltung und Projektmanagement.</p>
                    <p>Die erfassten Daten dienen dazu, vertragliche Verpflichtungen zu erfüllen und betriebliche
                        Prozesse effizient zu gestalten. Hierzu gehört die Abwicklung von Geschäftstransaktionen, das
                        Management von Kundenbeziehungen, die Optimierung von Vertriebsstrategien sowie die
                        Gewährleistung interner Rechnungs- und Finanzprozesse. Zusätzlich unterstützen die Daten die
                        Wahrung der Rechte des Verantwortlichen und fördern Verwaltungsaufgaben sowie die Organisation
                        des Unternehmens.</p>
                    <p>Personenbezogene Daten können an Dritte weitergegeben werden, sofern dies zur Erfüllung der
                        genannten Zwecke oder gesetzlicher Verpflichtungen notwendig<span
                            className="dsg-license-content-blurred de dsg-ttip-activate"
                            title="Bitte erwerben Sie eine Lizenz, um die Texte freizuschalten."> - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden. - premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext </span>
                    </p>
                    <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. der vollständige Name,
                            Wohnadresse, Kontaktinformationen, Kundennummer,<span
                                className="dsg-license-content-blurred de dsg-ttip-activate"
                                title="Bitte erwerben Sie eine Lizenz, um die Texte freizuschalten."> - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden. - premiumtext premiumtext premiumtext </span>);
                            Zahlungsdaten (z. B. Bankverbindungen, Rechnungen, Zahlungshistorie<span
                                className="dsg-license-content-blurred de dsg-ttip-activate"
                                title="Bitte erwerben Sie eine Lizenz, um die Texte freizuschalten."> - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden. - premiumtext premiumtext </span>);
                            Kontaktdaten (z. B. Post- und E-Mail-Adressen oder<span
                                className="dsg-license-content-blurred de dsg-ttip-activate"
                                title="Bitte erwerben Sie eine Lizenz, um die Texte freizuschalten."> - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden. - premiumtext premiumtext premiumtext </span>);
                            Inhaltsdaten (z. B. textliche oder bildliche Nachrichten und Beiträge sowie die sie
                            betreffenden Informationen, wie z. B. Angaben zur Autorenschaft<span
                                className="dsg-license-content-blurred de dsg-ttip-activate"
                                title="Bitte erwerben Sie eine Lizenz, um die Texte freizuschalten."> - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden. - premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext </span>);
                            Vertragsdaten (z. B. Vertragsgegenstand, Laufzeit, Kundenkategorie<span
                                className="dsg-license-content-blurred de dsg-ttip-activate"
                                title="Bitte erwerben Sie eine Lizenz, um die Texte freizuschalten."> - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden. - premiumtext premiumtext </span>);
                            Nutzungsdaten (z. B. Seitenaufrufe und Verweildauer, Klickpfade, Nutzungsintensität und
                            -frequenz, verwendete Gerätetypen und Betriebssysteme, Interaktionen mit<span
                                className="dsg-license-content-blurred de dsg-ttip-activate"
                                title="Bitte erwerben Sie eine Lizenz, um die Texte freizuschalten."> - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden. - premiumtext premiumtext premiumtext premiumtext premiumtext </span>).
                            Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
                            Identifikationsnummern, beteiligte<span
                                className="dsg-license-content-blurred de dsg-ttip-activate"
                                title="Bitte erwerben Sie eine Lizenz, um die Texte freizuschalten."> - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden. - premiumtext premiumtext premiumtext </span>).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Leistungsempfänger und Auftraggeber; Interessenten;
                            Kommunikationspartner; Geschäfts- und Vertragspartner. Kunden.
                        </li>
                        <li><strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher Leistungen und Erfüllung
                            vertraglicher Pflichten; Büro- und Organisationsverfahren; Geschäftsprozesse und
                            betriebswirtschaftliche Verfahren; Sicherheitsmaßnahmen. Bereitstellung unseres
                            Onlineangebotes und Nutzerfreundlichkeit.
                        </li>
                        <li><strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend Angaben im Abschnitt
                            "Allgemeine Informationen zur Datenspeicherung und Löschung".
                        </li>
                        <li className=""><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
                            Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO). Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
                            f) DSGVO).
                        </li>
                    </ul>
                    <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
                    <ul className="m-elements">
                        <li><strong>Kundenkonto: </strong>Kunden können innerhalb unseres Onlineangebotes ein Konto
                            anlegen (z. B. Kunden- bzw. Nutzerkonto, kurz "Kundenkonto"). Falls die Registrierung eines
                            Kundenkontos erforderlich ist, werden Kunden hierauf ebenso hingewiesen wie auf die für die
                            Registrierung erforderlichen Angaben. Die Kundenkonten sind nicht öffentlich und können von
                            Suchmaschinen nicht indexiert werden. Im Rahmen der Registrierung sowie anschließender
                            Anmeldungen und Nutzungen des Kundenkontos speichern wir die IP-Adressen der Kunden nebst
                            den Zugriffszeitpunkten, um die Registrierung nachweisen und etwaigem Missbrauch des
                            Kundenkontos vorbeugen zu können. Wurde das Kundenkonto gekündigt, werden die Daten des
                            Kundenkontos nach dem Kündigungszeitpunkt gelöscht, sofern sie nicht für andere Zwecke als
                            die Bereitstellung im Kundenkonto aufbewahrt werden oder aus rechtlichen Gründen aufbewahrt
                            werden müssen (z. B. interne Speicherung von Kundendaten, Bestellvorgängen oder Rechnungen).
                            Es liegt in der Verantwortung der Kunden, ihre Daten bei Kündigung des Kundenkontos zu
                            sichern; <span className=""><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</span>
                        </li>
                    </ul>
                    <h2 id="m605">Einsatz von Online-Plattformen zu Angebots- und Vertriebszwecken</h2><p>Wir bieten
                    unsere Leistungen auf Online-Plattformen, die von anderen Dienstanbietern betrieben werden, an. In
                    diesem Zusammenhang gelten zusätzlich zu unseren Datenschutzhinweisen die Datenschutzhinweise der
                    jeweiligen Plattformen. Dies gilt insbesondere im Hinblick auf die Durchführung des Zahlungsvorgangs
                    und der auf den Plattformen eingesetzten Verfahren zur Reichweitemessung und zum
                    interessensbezogenen Marketing.</p>
                    <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. der vollständige Name,
                            Wohnadresse, Kontaktinformationen, Kundennummer, etc.); Zahlungsdaten (z. B.
                            Bankverbindungen, Rechnungen, Zahlungshistorie); Kontaktdaten (z. B. Post- und
                            E-Mail-Adressen oder Telefonnummern); Vertragsdaten (z. B. Vertragsgegenstand, Laufzeit,
                            Kundenkategorie); Nutzungsdaten (z. B. Seitenaufrufe und Verweildauer, Klickpfade,
                            Nutzungsintensität und -frequenz, verwendete Gerätetypen und Betriebssysteme, Interaktionen
                            mit Inhalten und Funktionen). Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen,
                            Zeitangaben, Identifikationsnummern, beteiligte Personen).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Leistungsempfänger und Auftraggeber. Geschäfts- und
                            Vertragspartner.
                        </li>
                        <li><strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher Leistungen und Erfüllung
                            vertraglicher Pflichten; Marketing. Geschäftsprozesse und betriebswirtschaftliche Verfahren.
                        </li>
                        <li><strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend Angaben im Abschnitt
                            "Allgemeine Informationen zur Datenspeicherung und Löschung".
                        </li>
                        <li className=""><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
                            Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO). Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
                            f) DSGVO).
                        </li>
                    </ul>
                    <h2 id="m326">Zahlungsverfahren</h2><p>Im Rahmen von Vertrags- und sonstigen Rechtsbeziehungen,
                    aufgrund gesetzlicher Pflichten oder sonst auf Grundlage unserer berechtigten Interessen bieten wir
                    den betroffenen Personen effiziente und sichere Zahlungsmöglichkeiten an und setzen hierzu neben
                    Banken und Kreditinstituten weitere Dienstleister ein (zusammenfassend "Zahlungsdienstleister").</p>
                    <p>Zu den durch die Zahlungsdienstleister verarbeiteten Daten gehören Bestandsdaten, wie z. B. der
                        Name und die Adresse, Bankdaten, wie z. B. Kontonummern oder Kreditkartennummern, Passwörter,
                        TANs und Prüfsummen sowie die Vertrags-, Summen- und empfängerbezogenen Angaben. Die Angaben
                        sind erforderlich, um die Transaktionen durchzuführen. Die eingegebenen Daten werden jedoch nur
                        durch die Zahlungsdienstleister verarbeitet und bei diesen gespeichert. D. h., wir erhalten
                        keine konto- oder kreditkartenbezogenen Informationen, sondern lediglich Informationen mit
                        Bestätigung oder Negativbeauskunftung der Zahlung. Unter Umständen werden die Daten seitens der
                        Zahlungsdienstleister an Wirtschaftsauskunfteien übermittelt. Diese Übermittlung bezweckt die
                        Identitäts- und Bonitätsprüfung. Hierzu verweisen wir auf die AGB und die Datenschutzhinweise
                        der Zahlungsdienstleister.</p>
                    <p>Für die Zahlungsgeschäfte gelten die Geschäftsbedingungen und die Datenschutzhinweise der
                        jeweiligen Zahlungsdienstleister, welche innerhalb der jeweiligen Webseiten bzw.
                        Transaktionsapplikationen abrufbar sind. Wir verweisen auf diese ebenfalls zwecks weiterer
                        Informationen und Geltendmachung von Widerrufs-, Auskunfts- und anderen Betroffenenrechten.</p>
                    <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. der vollständige Name,
                            Wohnadresse, Kontaktinformationen, Kundennummer, etc.); Zahlungsdaten (z. B.
                            Bankverbindungen, Rechnungen, Zahlungshistorie); Vertragsdaten (z. B. Vertragsgegenstand,
                            Laufzeit, Kundenkategorie); Nutzungsdaten (z. B. Seitenaufrufe und Verweildauer, Klickpfade,
                            Nutzungsintensität und -frequenz, verwendete Gerätetypen und Betriebssysteme, Interaktionen
                            mit Inhalten und Funktionen). Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen,
                            Zeitangaben, Identifikationsnummern, beteiligte Personen).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Leistungsempfänger und Auftraggeber; Geschäfts- und
                            Vertragspartner. Interessenten.
                        </li>
                        <li><strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher Leistungen und Erfüllung
                            vertraglicher Pflichten. Geschäftsprozesse und betriebswirtschaftliche Verfahren.
                        </li>
                        <li><strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend Angaben im Abschnitt
                            "Allgemeine Informationen zur Datenspeicherung und Löschung".
                        </li>
                        <li className=""><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
                            Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO). Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
                            f) DSGVO).
                        </li>
                    </ul>
                    <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
                    <ul className="m-elements">
                        <li><strong>Stripe: </strong>Zahlungsdienstleistungen (technische Anbindung von
                            Online-Bezahlmethoden); <strong>Dienstanbieter:</strong> Stripe, Inc., 510 Townsend Street,
                            San Francisco, CA 94103, USA; <span className=""><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO); </span><strong>Website:</strong>
                            <a href="https://stripe.com">https://stripe.com</a>; <strong>Datenschutzerklärung:</strong> <a
                                href="https://stripe.com/de/privacy">https://stripe.com/de/privacy</a>. <strong>Grundlage
                                Drittlandtransfers:</strong> Data Privacy Framework (DPF).
                        </li>
                    </ul>
                    <h2 id="m225">Bereitstellung des Onlineangebots und Webhosting</h2><p>Wir verarbeiten die Daten der
                    Nutzer, um ihnen unsere Online-Dienste zur Verfügung stellen zu können. Zu diesem Zweck verarbeiten
                    wir die IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und Funktionen unserer
                    Online-Dienste an den Browser oder das Endgerät der Nutzer zu übermitteln.</p>
                    <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B. Seitenaufrufe und
                            Verweildauer, Klickpfade, Nutzungsintensität und -frequenz, verwendete Gerätetypen und
                            Betriebssysteme, Interaktionen mit Inhalten und Funktionen); Meta-, Kommunikations- und
                            Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte
                            Personen); Protokolldaten (z. B. Logfiles betreffend Logins oder den Abruf von Daten oder
                            Zugriffszeiten.). Inhaltsdaten (z. B. textliche oder bildliche Nachrichten und Beiträge
                            sowie die sie betreffenden Informationen, wie z. B. Angaben zur Autorenschaft oder Zeitpunkt
                            der Erstellung).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher, Nutzer von
                            Onlinediensten).
                        </li>
                        <li><strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und
                            Nutzerfreundlichkeit; Informationstechnische Infrastruktur (Betrieb und Bereitstellung von
                            Informationssystemen und technischen Geräten (Computer, Server etc.).).
                            Sicherheitsmaßnahmen.
                        </li>
                        <li><strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend Angaben im Abschnitt
                            "Allgemeine Informationen zur Datenspeicherung und Löschung".
                        </li>
                        <li className=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                            lit. f) DSGVO).
                        </li>
                    </ul>
                    <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
                    <ul className="m-elements">
                        <li><strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Der Zugriff auf unser
                            Onlineangebot wird in Form von sogenannten "Server-Logfiles" protokolliert. Zu den
                            Serverlogfiles können die Adresse und der Name der abgerufenen Webseiten und Dateien, Datum
                            und Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über erfolgreichen Abruf,
                            Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte
                            Seite) und im Regelfall IP-Adressen und der anfragende Provider gehören. Die Serverlogfiles
                            können zum einen zu Sicherheitszwecken eingesetzt werden, z. B. um eine Überlastung der
                            Server zu vermeiden (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten
                            DDoS-Attacken), und zum anderen, um die Auslastung der Server und ihre Stabilität
                            sicherzustellen; <span className=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO). </span><strong>Löschung
                                von Daten:</strong> Logfile-Informationen werden für die Dauer von maximal 30 Tagen
                            gespeichert und danach gelöscht oder anonymisiert. Daten, deren weitere Aufbewahrung zu
                            Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls von
                            der Löschung ausgenommen.
                        </li>
                        <li><strong>E-Mail-Versand und -Hosting: </strong>Die von uns in Anspruch genommenen
                            Webhosting-Leistungen umfassen ebenfalls den Versand, den Empfang sowie die Speicherung von
                            E-Mails. Zu diesen Zwecken werden die Adressen der Empfänger sowie Absender als auch weitere
                            Informationen betreffend den E-Mailversand (z. B. die beteiligten Provider) sowie die
                            Inhalte der jeweiligen E-Mails verarbeitet. Die vorgenannten Daten können ferner zu Zwecken
                            der Erkennung von SPAM verarbeitet werden. Wir bitten darum, zu beachten, dass E-Mails im
                            Internet grundsätzlich nicht verschlüsselt versendet werden. Im Regelfall werden E-Mails
                            zwar auf dem Transportweg verschlüsselt, aber (sofern kein sogenanntes
                            Ende-zu-Ende-Verschlüsselungsverfahren eingesetzt wird) nicht auf den Servern, von denen sie
                            abgesendet und empfangen werden. Wir können daher für den Übertragungsweg der E-Mails
                            zwischen dem Absender und dem Empfang auf unserem Server keine Verantwortung
                            übernehmen; <span className=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</span>
                        </li>
                    </ul>
                    <h2 id="m134">Einsatz von Cookies</h2><p>Cookies sind kleine Textdateien bzw. sonstige
                    Speichervermerke, die Informationen auf Endgeräten speichern und aus ihnen auslesen. Zum Beispiel,
                    um den Log-in-Status in einem Nutzerkonto, einen Warenkorbinhalt in einem E-Shop, die aufgerufenen
                    Inhalte oder verwendete Funktionen eines Onlineangebots zu speichern. Cookies können ferner in Bezug
                    auf unterschiedliche Anliegen Einsatz finden, etwa zu Zwecken der Funktionsfähigkeit, der Sicherheit
                    und des Komforts von Onlineangeboten sowie der Erstellung von Analysen der Besucherströme.</p>
                    <p><strong>Hinweise zur Einwilligung: </strong>Wir setzen Cookies im Einklang mit den gesetzlichen
                        Vorschriften ein. Daher holen wir von den Nutzern eine vorhergehende Einwilligung ein, es sei
                        denn, sie ist laut Gesetzeslage nicht gefordert. Eine Erlaubnis ist insbesondere nicht
                        notwendig, wenn das Speichern und das Auslesen der Informationen, also auch von Cookies,
                        unbedingt erforderlich sind, um den Nutzern einen von ihnen ausdrücklich gewünschten
                        Telemediendienst (also unser Onlineangebot) zur Verfügung zu stellen. Die widerrufliche
                        Einwilligung wird ihnen gegenüber deutlich kommuniziert und enthält die Informationen zur
                        jeweiligen Cookie-Nutzung.</p>
                    <p><strong>Hinweise zu datenschutzrechtlichen Rechtsgrundlagen: </strong>Auf welcher
                        datenschutzrechtlichen Grundlage wir die personenbezogenen Daten der Nutzer mithilfe von Cookies
                        verarbeiten, hängt davon ab, ob wir sie um eine Einwilligung bitten. Falls die Nutzer
                        akzeptieren, ist die Rechtsgrundlage der Verwertung ihrer Daten die erklärte Einwilligung.
                        Andernfalls werden die mithilfe von Cookies verwerteten Daten auf Grundlage unserer berechtigten
                        Interessen (z. B. an einem betriebswirtschaftlichen Betrieb unseres Onlineangebots und der
                        Verbesserung seiner Nutzbarkeit) verarbeitet oder, falls dies im Rahmen der Erfüllung unserer
                        vertraglichen Pflichten erfolgt, wenn der Einsatz von Cookies erforderlich ist, um unseren
                        vertraglichen Verpflichtungen nachzukommen. Zu welchen Zwecken die Cookies von uns verwertet
                        werden, darüber klären wir im Laufe dieser Datenschutzerklärung oder im Rahmen von unseren
                        Einwilligungs- und Verarbeitungsprozessen auf.</p>
                    <p><strong>Speicherdauer: </strong>Im Hinblick auf die Speicherdauer werden die folgenden Arten von
                        Cookies unterschieden:</p>
                    <ul>
                        <li><strong>Temporäre Cookies (auch: Session- oder Sitzungscookies):</strong> Temporäre Cookies
                            werden spätestens gelöscht, nachdem ein Nutzer ein Onlineangebot verlassen und sein Endgerät
                            (z. B. Browser oder mobile Applikation) geschlossen hat.
                        </li>
                        <li><strong>Permanente Cookies:</strong> Permanente Cookies bleiben auch nach dem Schließen des
                            Endgeräts gespeichert. So können beispielsweise der Log-in-Status gespeichert und bevorzugte
                            Inhalte direkt angezeigt werden, wenn der Nutzer eine Website erneut besucht. Ebenso können
                            die mithilfe von Cookies erhobenen Nutzerdaten zur Reichweitenmessung Verwendung finden.
                            Sofern wir Nutzern keine expliziten Angaben zur Art und Speicherdauer von Cookies mitteilen
                            (z. B. im Rahmen der Einholung der Einwilligung), sollten sie davon ausgehen, dass diese
                            permanent sind und die Speicherdauer bis zu zwei Jahre betragen kann.
                        </li>
                    </ul>
                    <p><strong>Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-out): </strong>Nutzer können die
                        von ihnen abgegebenen Einwilligungen jederzeit widerrufen und zudem einen Widerspruch gegen die
                        Verarbeitung entsprechend den gesetzlichen Vorgaben, auch mittels der Privatsphäre-Einstellungen
                        ihres Browsers, erklären.</p>
                    <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Meta-, Kommunikations- und Verfahrensdaten (z. B.
                            IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte Personen).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher, Nutzer von
                            Onlinediensten).
                        </li>
                        <li className=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                            lit. f) DSGVO). Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).
                        </li>
                    </ul>
                    <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
                    <ul className="m-elements">
                        <li><strong>Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung: </strong>Wir setzen
                            eine Einwilligungs-Management-Lösung ein, bei der die Einwilligung der Nutzer zur Verwendung
                            von Cookies oder zu den im Rahmen der Einwilligungs-Management-Lösung genannten Verfahren
                            und Anbietern eingeholt wird. Dieses Verfahren dient der Einholung, Protokollierung,
                            Verwaltung und dem Widerruf von Einwilligungen, insbesondere bezogen auf den Einsatz von
                            Cookies und vergleichbaren Technologien, die zur Speicherung, zum Auslesen und zur
                            Verarbeitung von Informationen auf den Endgeräten der Nutzer eingesetzt werden. Im Rahmen
                            dieses Verfahrens werden die Einwilligungen der Nutzer für die Nutzung von Cookies und die
                            damit verbundenen Verarbeitungen von Informationen, einschließlich der im
                            Einwilligungs-Management-Verfahren genannten spezifischen Verarbeitungen und Anbieter,
                            eingeholt. Die Nutzer haben zudem die Möglichkeit, ihre Einwilligungen zu verwalten und zu
                            widerrufen. Die Einwilligungserklärungen werden gespeichert, um eine erneute Abfrage zu
                            vermeiden und den Nachweis der Einwilligung gemäß der gesetzlichen Anforderungen führen zu
                            können. Die Speicherung erfolgt serverseitig und/oder in einem Cookie (sogenanntes
                            Opt-In-Cookie) oder mittels vergleichbarer Technologien, um die Einwilligung einem
                            spezifischen Nutzer oder dessen Gerät zuordnen zu können. Sofern keine spezifischen Angaben
                            zu den Anbietern von Einwilligungs-Management-Diensten vorliegen, gelten folgende allgemeine
                            Hinweise: Die Dauer der Speicherung der Einwilligung beträgt bis zu zwei Jahre. Dabei wird
                            ein pseudonymer Nutzer-Identifikator erstellt, der zusammen mit dem Zeitpunkt der
                            Einwilligung, den Angaben zum Umfang der Einwilligung (z. B. betreffende Kategorien von
                            Cookies und/oder Diensteanbieter) sowie Informationen über den Browser, das System und das
                            verwendete Endgerät gespeichert wird; <span className=""><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).</span>
                        </li>
                    </ul>
                    <h2 id="m367">Registrierung, Anmeldung und Nutzerkonto</h2><p>Nutzer können ein Nutzerkonto anlegen.
                    Im Rahmen der Registrierung werden den Nutzern die erforderlichen Pflichtangaben mitgeteilt und zu
                    Zwecken der Bereitstellung des Nutzerkontos auf Grundlage vertraglicher Pflichterfüllung
                    verarbeitet. Zu den verarbeiteten Daten gehören insbesondere die Login-Informationen (Nutzername,
                    Passwort sowie eine E-Mail-Adresse).</p>
                    <p>Im Rahmen der Inanspruchnahme unserer Registrierungs- und Anmeldefunktionen sowie der Nutzung des
                        Nutzerkontos speichern wir die IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Die
                        Speicherung erfolgt auf Grundlage unserer berechtigten Interessen als auch jener der Nutzer an
                        einem Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten an
                        Dritte erfolgt grundsätzlich nicht, es sei denn, sie ist zur Verfolgung unserer Ansprüche
                        erforderlich oder es besteht eine gesetzliche Verpflichtung hierzu.</p>
                    <p>Die Nutzer können über Vorgänge, die für deren Nutzerkonto relevant sind, wie z. B. technische
                        Änderungen, per E-Mail informiert werden.</p>
                    <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. der vollständige Name,
                            Wohnadresse, Kontaktinformationen, Kundennummer, etc.); Kontaktdaten (z. B. Post- und
                            E-Mail-Adressen oder Telefonnummern); Inhaltsdaten (z. B. textliche oder bildliche
                            Nachrichten und Beiträge sowie die sie betreffenden Informationen, wie z. B. Angaben zur
                            Autorenschaft oder Zeitpunkt der Erstellung); Nutzungsdaten (z. B. Seitenaufrufe und
                            Verweildauer, Klickpfade, Nutzungsintensität und -frequenz, verwendete Gerätetypen und
                            Betriebssysteme, Interaktionen mit Inhalten und Funktionen). Protokolldaten (z. B. Logfiles
                            betreffend Logins oder den Abruf von Daten oder Zugriffszeiten.).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher, Nutzer von
                            Onlinediensten).
                        </li>
                        <li><strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher Leistungen und Erfüllung
                            vertraglicher Pflichten; Sicherheitsmaßnahmen; Organisations- und Verwaltungsverfahren.
                            Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
                        </li>
                        <li><strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend Angaben im Abschnitt
                            "Allgemeine Informationen zur Datenspeicherung und Löschung". Löschung nach Kündigung.
                        </li>
                        <li className=""><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
                            Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO). Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
                            f) DSGVO).
                        </li>
                    </ul>
                    <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
                    <ul className="m-elements">
                        <li><strong>Profile der Nutzer sind nicht öffentlich: </strong>Die Profile der Nutzer sind
                            öffentlich nicht sichtbar und nicht zugänglich.
                        </li>
                    </ul>
                    <h2 id="m182">Kontakt- und Anfrageverwaltung</h2><p>Bei der Kontaktaufnahme mit uns (z. B. per Post,
                    Kontaktformular, E-Mail, Telefon oder via soziale Medien) sowie im Rahmen bestehender Nutzer- und
                    Geschäftsbeziehungen werden die Angaben der anfragenden Personen verarbeitet, soweit dies zur
                    Beantwortung der Kontaktanfragen und etwaiger angefragter Maßnahmen erforderlich ist.</p>
                    <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. der vollständige Name,
                            Wohnadresse, Kontaktinformationen, Kundennummer, etc.); Kontaktdaten (z. B. Post- und
                            E-Mail-Adressen oder Telefonnummern); Inhaltsdaten (z. B. textliche oder bildliche
                            Nachrichten und Beiträge sowie die sie betreffenden Informationen, wie z. B. Angaben zur
                            Autorenschaft oder Zeitpunkt der Erstellung); Nutzungsdaten (z. B. Seitenaufrufe und
                            Verweildauer, Klickpfade, Nutzungsintensität und -frequenz, verwendete Gerätetypen und
                            Betriebssysteme, Interaktionen mit Inhalten und Funktionen). Meta-, Kommunikations- und
                            Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte
                            Personen).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
                        <li><strong>Zwecke der Verarbeitung:</strong> Kommunikation; Organisations- und
                            Verwaltungsverfahren; Feedback (z. B. Sammeln von Feedback via Online-Formular).
                            Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
                        </li>
                        <li><strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend Angaben im Abschnitt
                            "Allgemeine Informationen zur Datenspeicherung und Löschung".
                        </li>
                        <li className=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                            lit. f) DSGVO). Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b)
                            DSGVO).
                        </li>
                    </ul>
                    <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
                    <ul className="m-elements">
                        <li><strong>Kontaktformular: </strong>Bei Kontaktaufnahme über unser Kontaktformular, per E-Mail
                            oder anderen Kommunikationswegen, verarbeiten wir die uns übermittelten personenbezogenen
                            Daten zur Beantwortung und Bearbeitung des jeweiligen Anliegens. Dies umfasst in der Regel
                            Angaben wie Name, Kontaktinformationen und gegebenenfalls weitere Informationen, die uns
                            mitgeteilt werden und zur angemessenen Bearbeitung erforderlich sind. Wir nutzen diese Daten
                            ausschließlich für den angegebenen Zweck der Kontaktaufnahme und Kommunikation; <span
                                className=""><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</span>
                        </li>
                    </ul>
                    <h2 id="m1643">Push-Nachrichten</h2><p>Mit der Zustimmung der Nutzer, können wir den Nutzern so
                    genannte "Push-Benachrichtigungen" zusenden. Dabei handelt es sich um Nachrichten, die auf den
                    Bildschirmen, Endgeräten oder in Browsern der Nutzer angezeigt werden, auch wenn unser Onlinedienst
                    gerade nicht aktiv genutzt wird. </p>
                    <p>Um sich für die Push-Nachrichten anzumelden, müssen Nutzer die Abfrage ihres Browsers bzw.
                        Endgerätes zum Erhalt der Push-Nachrichten bestätigen. Dieser Zustimmungsprozess wird
                        dokumentiert und gespeichert. Die Speicherung ist erforderlich, um zu erkennen, ob Nutzer dem
                        Empfang der Push-Nachrichten zugestimmt haben sowie um die Zustimmung nachweisen zu können. Zu
                        diesen Zwecken wird ein pseudonymer Identifikator des Browsers (sog. "Push-Token") oder die
                        Geräte-ID eines Endgerätes gespeichert.</p>
                    <p>Die Push-Nachrichten können zum einen für die Erfüllung von vertraglichen Pflichten erforderlich
                        sein (z. B. für die Nutzung unseres Onlineangebotes relevante technische und organisatorische
                        Informationen) und<span className="dsg-license-content-blurred de dsg-ttip-activate"
                                                title="Bitte erwerben Sie eine Lizenz, um die Texte freizuschalten."> - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden. - premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext premiumtext </span>
                    </p>
                    <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B. Seitenaufrufe und
                            Verweildauer, Klickpfade, Nutzungsintensität und -frequenz, verwendete Gerätetypen und
                            Betriebssysteme, Interaktionen mit<span
                                className="dsg-license-content-blurred de dsg-ttip-activate"
                                title="Bitte erwerben Sie eine Lizenz, um die Texte freizuschalten."> - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden. - premiumtext premiumtext premiumtext premiumtext premiumtext </span>).
                            Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
                            Identifikationsnummern, beteiligte<span
                                className="dsg-license-content-blurred de dsg-ttip-activate"
                                title="Bitte erwerben Sie eine Lizenz, um die Texte freizuschalten."> - Dieser Textbereich muss mit einer Premium Lizenz freischaltet werden. - premiumtext premiumtext premiumtext </span>).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
                        <li><strong>Zwecke der Verarbeitung:</strong> Kommunikation. Bereitstellung unseres
                            Onlineangebotes und Nutzerfreundlichkeit.
                        </li>
                        <li><strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend Angaben im Abschnitt
                            "Allgemeine Informationen zur Datenspeicherung und Löschung". Löschung nach Kündigung.
                        </li>
                        <li className=""><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
                            DSGVO). Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                        </li>
                    </ul>
                    <h2 id="m263">Webanalyse, Monitoring und Optimierung</h2><p>Die Webanalyse (auch als
                    „Reichweitenmessung" bezeichnet) dient der Auswertung der Besucherströme unseres Onlineangebots und
                    kann Verhalten, Interessen oder demografische Informationen zu den Besuchern, wie beispielsweise
                    Alter oder Geschlecht, als pseudonyme Werte umfassen. Mithilfe der Reichweitenanalyse können wir zum
                    Beispiel erkennen, zu welcher Zeit unser Onlineangebot oder dessen Funktionen beziehungsweise
                    Inhalte am häufigsten genutzt werden, oder zur Wiederverwendung einladen. Ebenso ist es uns möglich,
                    nachzuvollziehen, welche Bereiche der Optimierung bedürfen. </p>
                    <p>Neben der Webanalyse können wir auch Testverfahren einsetzen, um etwa unterschiedliche Versionen
                        unseres Onlineangebots oder seiner Bestandteile zu testen und zu optimieren.</p>
                    <p>Sofern nachfolgend nicht anders angegeben, können zu diesen Zwecken Profile, also zu einem
                        Nutzungsvorgang zusammengefasste Daten, angelegt und Informationen in einem Browser bzw. in
                        einem Endgerät gespeichert und dann ausgelesen werden. Zu den erhobenen Angaben gehören
                        insbesondere besuchte Websites und dort genutzte Elemente sowie technische Auskünfte, wie etwa
                        der verwendete Browser, das benutzte Computersystem sowie Angaben zu Nutzungszeiten. Sofern sich
                        Nutzer in die Erhebung ihrer Standortdaten uns gegenüber oder gegenüber den Anbietern der von
                        uns eingesetzten Dienste einverstanden erklärt haben, ist auch die Verarbeitung von
                        Standortdaten möglich.</p>
                    <p>Darüber hinaus werden die IP-Adressen der Nutzer gespeichert. Jedoch nutzen wir ein
                        IP-Masking-Verfahren (d. h. Pseudonymisierung durch Kürzung der IP-Adresse) zum Schutz der
                        Nutzer. Generell werden die im Rahmen von Webanalyse, A/B-Testings und Optimierung keine
                        Klardaten der Nutzer (wie z. B. E-Mail-Adressen oder Namen) gespeichert, sondern Pseudonyme. Das
                        heißt, wir als auch die Anbieter der eingesetzten Software kennen nicht die tatsächliche
                        Identität der Nutzer, sondern nur die zum Zweck der jeweiligen Verfahren in deren Profilen
                        gespeicherten Angaben.</p>
                    <p>Hinweise zu Rechtsgrundlagen: Sofern wir die Nutzer um deren Einwilligung in den Einsatz der
                        Drittanbieter bitten, stellt die Rechtsgrundlage der Datenverarbeitung die Einwilligung dar.
                        Ansonsten werden die Nutzerdaten auf Grundlage unserer berechtigten Interessen (d. h. Interesse
                        an effizienten, wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet. In diesem
                        Zusammenhang möchten wir Sie auch auf die Informationen zur Verwendung von Cookies in dieser
                        Datenschutzerklärung hinweisen.</p>
                    <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B. Seitenaufrufe und
                            Verweildauer, Klickpfade, Nutzungsintensität und -frequenz, verwendete Gerätetypen und
                            Betriebssysteme, Interaktionen mit Inhalten und Funktionen). Meta-, Kommunikations- und
                            Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte
                            Personen).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher, Nutzer von
                            Onlinediensten).
                        </li>
                        <li><strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung (z. B. Zugriffsstatistiken,
                            Erkennung wiederkehrender Besucher); Profile mit nutzerbezogenen Informationen (Erstellen
                            von Nutzerprofilen). Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
                        </li>
                        <li><strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend Angaben im Abschnitt
                            "Allgemeine Informationen zur Datenspeicherung und Löschung". Speicherung von Cookies von
                            bis zu 2 Jahren (Sofern nicht anders angegeben, können Cookies und ähnliche Speichermethoden
                            für einen Zeitraum von zwei Jahren auf den Geräten der Nutzer gespeichert werden.).
                        </li>
                        <li><strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung der IP-Adresse).</li>
                        <li className=""><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
                            DSGVO). Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                        </li>
                    </ul>
                    <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
                    <ul className="m-elements">
                        <li><strong>Google Analytics: </strong>Wir verwenden Google Analytics zur Messung und Analyse
                            der Nutzung unseres Onlineangebotes auf der Grundlage einer pseudonymen
                            Nutzeridentifikationsnummer. Diese Identifikationsnummer enthält keine eindeutigen Daten,
                            wie Namen oder E-Mail-Adressen. Sie dient dazu, Analyseinformationen einem Endgerät
                            zuzuordnen, um zu erkennen, welche Inhalte die Nutzer innerhalb eines oder verschiedener
                            Nutzungsvorgänge aufgerufen haben, welche Suchbegriffe sie verwendet haben, diese erneut
                            aufgerufen haben oder mit unserem Onlineangebot interagiert haben. Ebenso werden der
                            Zeitpunkt der Nutzung und deren Dauer gespeichert, sowie die Quellen der Nutzer, die auf
                            unser Onlineangebot verweisen und technische Aspekte ihrer Endgeräte und Browser.<br/>Dabei
                            werden pseudonyme Profile von Nutzern mit Informationen aus der Nutzung verschiedener Geräte
                            erstellt, wobei Cookies eingesetzt werden können. Google Analytics protokolliert und
                            speichert keine individuellen IP-Adressen für EU-Nutzer. Analytics stellt jedoch grobe
                            geografische Standortdaten bereit, indem es die folgenden Metadaten von IP-Adressen
                            ableitet: Stadt (und der abgeleitete Breiten- und Längengrad der Stadt), Kontinent, Land,
                            Region, Subkontinent (und ID-basierte Gegenstücke). Beim EU-Datenverkehr werden die
                            IP-Adressdaten ausschließlich für diese Ableitung von Geolokalisierungsdaten verwendet,
                            bevor sie sofort gelöscht werden. Sie werden nicht protokolliert, sind nicht zugänglich und
                            werden nicht für weitere Verwendungszwecke genutzt. Wenn Google Analytics Messdaten sammelt,
                            werden alle IP-Abfragen auf EU-basierten Servern durchgeführt, bevor der Verkehr zur
                            Verarbeitung an Analytics-Server weitergeleitet
                            wird; <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon House, Barrow Street,
                            Dublin 4, Irland; <span className=""><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO); </span><strong>Website:</strong>
                            <a href="https://marketingplatform.google.com/intl/de/about/analytics/"
                               >https://marketingplatform.google.com/intl/de/about/analytics/</a>; <strong>Sicherheitsmaßnahmen:</strong> IP-Masking
                            (Pseudonymisierung der IP-Adresse); <strong>Datenschutzerklärung:</strong> <a
                                href="https://policies.google.com/privacy"
                                >https://policies.google.com/privacy</a>; <strong>Auftragsverarbeitungsvertrag:</strong>
                            <a href="https://business.safety.google/adsprocessorterms/"
                               >https://business.safety.google/adsprocessorterms/</a>; <strong>Grundlage
                                Drittlandtransfers:</strong> Data Privacy Framework (DPF); <strong>Widerspruchsmöglichkeit
                                (Opt-Out):</strong> Opt-Out-Plugin: <a
                                href="https://tools.google.com/dlpage/gaoptout?hl=de"
                                >https://tools.google.com/dlpage/gaoptout?hl=de</a>, Einstellungen für
                            die Darstellung von Werbeeinblendungen: <a
                                href="https://myadcenter.google.com/personalizationoff"
                                >https://myadcenter.google.com/personalizationoff</a>. <strong>Weitere
                                Informationen:</strong> <a href="https://business.safety.google/adsservices/"
                                                           >https://business.safety.google/adsservices/</a> (Arten
                            der Verarbeitung sowie der verarbeiteten Daten).
                        </li>
                    </ul>
                    <h2 id="m136">Präsenzen in sozialen Netzwerken (Social Media)</h2><p>Wir unterhalten Onlinepräsenzen
                    innerhalb sozialer Netzwerke und verarbeiten in diesem Rahmen Nutzerdaten, um mit den dort aktiven
                    Nutzern zu kommunizieren oder Informationen über uns anzubieten.</p>
                    <p>Wir weisen darauf hin, dass dabei Nutzerdaten außerhalb des Raumes der Europäischen Union
                        verarbeitet werden können. Hierdurch können sich für die Nutzer Risiken ergeben, weil so zum
                        Beispiel die Durchsetzung der Nutzerrechte erschwert werden könnte.</p>
                    <p>Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im Regelfall für Marktforschungs-
                        und Werbezwecke verarbeitet. So können beispielsweise anhand des Nutzungsverhaltens und sich
                        daraus ergebender Interessen der Nutzer Nutzungsprofile erstellt werden. Letztere finden
                        möglicherweise wiederum Verwendung, um etwa Werbeanzeigen innerhalb und außerhalb der Netzwerke
                        zu schalten, die mutmaßlich den Interessen der Nutzer entsprechen. Daher werden im Regelfall
                        Cookies auf den Rechnern der Nutzer gespeichert, in denen das Nutzungsverhalten und die
                        Interessen der Nutzer gespeichert werden. Zudem können in den Nutzungsprofilen auch Daten
                        unabhängig der von den Nutzern verwendeten Geräten gespeichert werden (insbesondere, wenn sie
                        Mitglieder der jeweiligen Plattformen und dort eingeloggt sind).</p>
                    <p>Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und der
                        Widerspruchsmöglichkeiten (Opt-out) verweisen wir auf die Datenschutzerklärungen und Angaben der
                        Betreiber der jeweiligen Netzwerke.</p>
                    <p>Auch im Fall von Auskunftsanfragen und der Geltendmachung von Betroffenenrechten weisen wir
                        darauf hin, dass diese am effektivsten bei den Anbietern geltend gemacht werden können. Nur
                        Letztere haben jeweils Zugriff auf die Nutzerdaten und können direkt entsprechende Maßnahmen
                        ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe benötigen, dann können Sie sich an uns
                        wenden.</p>
                    <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z. B. Post- und E-Mail-Adressen oder
                            Telefonnummern); Inhaltsdaten (z. B. textliche oder bildliche Nachrichten und Beiträge sowie
                            die sie betreffenden Informationen, wie z. B. Angaben zur Autorenschaft oder Zeitpunkt der
                            Erstellung). Nutzungsdaten (z. B. Seitenaufrufe und Verweildauer, Klickpfade,
                            Nutzungsintensität und -frequenz, verwendete Gerätetypen und Betriebssysteme, Interaktionen
                            mit Inhalten und Funktionen).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher, Nutzer von
                            Onlinediensten).
                        </li>
                        <li><strong>Zwecke der Verarbeitung:</strong> Kommunikation; Feedback (z. B. Sammeln von
                            Feedback via Online-Formular). Öffentlichkeitsarbeit.
                        </li>
                        <li><strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend Angaben im Abschnitt
                            "Allgemeine Informationen zur Datenspeicherung und Löschung".
                        </li>
                        <li className=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                            lit. f) DSGVO).
                        </li>
                    </ul>
                    <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
                    <ul className="m-elements">
                        <li><strong>Instagram: </strong>Soziales Netzwerk, ermöglicht das Teilen von Fotos und Videos,
                            das Kommentieren und Favorisieren von Beiträgen, Nachrichtenversand, Abonnieren von Profilen
                            und Seiten; <strong>Dienstanbieter:</strong> Meta Platforms Ireland Limited, Merrion Road,
                            Dublin 4, D04 X2K5, Irland; <span className=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); </span><strong>Website:</strong>
                            <a href="https://www.instagram.com"
                               >https://www.instagram.com</a>; <strong>Datenschutzerklärung:</strong> <a
                                href="https://privacycenter.instagram.com/policy/"
                                >https://privacycenter.instagram.com/policy/</a>. <strong>Grundlage
                                Drittlandtransfers:</strong> Data Privacy Framework (DPF).
                        </li>
                    </ul>
                    <h2 id="m328">Plug-ins und eingebettete Funktionen sowie Inhalte</h2><p>Wir binden Funktions- und
                    Inhaltselemente in unser Onlineangebot ein, die von den Servern ihrer jeweiligen Anbieter
                    (nachfolgend als „Drittanbieter" bezeichnet) bezogen werden. Dabei kann es sich zum Beispiel um
                    Grafiken, Videos oder Stadtpläne handeln (nachfolgend einheitlich als „Inhalte" bezeichnet).</p>
                    <p>Die Einbindung setzt immer voraus, dass die Drittanbieter dieser Inhalte die IP-Adresse der
                        Nutzer verarbeiten, da sie ohne IP-Adresse die Inhalte nicht an deren Browser senden könnten.
                        Die IP-Adresse ist damit für die Darstellung dieser Inhalte oder Funktionen erforderlich. Wir
                        bemühen uns, nur solche Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich
                        zur Auslieferung der Inhalte anzuwenden. Drittanbieter können ferner sogenannte Pixel-Tags
                        (unsichtbare Grafiken, auch als „Web Beacons" bezeichnet) für statistische oder Marketingzwecke
                        einsetzen. Durch die „Pixel-Tags" können Informationen, wie etwa der Besucherverkehr auf den
                        Seiten dieser Website, ausgewertet werden. Die pseudonymen Informationen können darüber hinaus
                        in Cookies auf dem Gerät der Nutzer gespeichert werden und unter anderem technische Auskünfte
                        zum Browser und zum Betriebssystem, zu verweisenden Websites, zur Besuchszeit sowie weitere
                        Angaben zur Nutzung unseres Onlineangebots enthalten, aber auch mit solchen Informationen aus
                        anderen Quellen verbunden werden.</p>
                    <p><strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um ihre Einwilligung in den
                        Einsatz der Drittanbieter bitten, stellt die Rechtsgrundlage der Datenverarbeitung die Erlaubnis
                        dar. Ansonsten werden die Nutzerdaten auf Grundlage unserer berechtigten Interessen (d. h.
                        Interesse an effizienten, wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet. In
                        diesem Zusammenhang möchten wir Sie auch auf die Informationen zur Verwendung von Cookies in
                        dieser Datenschutzerklärung hinweisen.</p>
                    <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B. Seitenaufrufe und
                            Verweildauer, Klickpfade, Nutzungsintensität und -frequenz, verwendete Gerätetypen und
                            Betriebssysteme, Interaktionen mit Inhalten und Funktionen). Meta-, Kommunikations- und
                            Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte
                            Personen).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher, Nutzer von
                            Onlinediensten).
                        </li>
                        <li><strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und
                            Nutzerfreundlichkeit.
                        </li>
                        <li><strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend Angaben im Abschnitt
                            "Allgemeine Informationen zur Datenspeicherung und Löschung". Speicherung von Cookies von
                            bis zu 2 Jahren (Sofern nicht anders angegeben, können Cookies und ähnliche Speichermethoden
                            für einen Zeitraum von zwei Jahren auf den Geräten der Nutzer gespeichert werden.).
                        </li>
                        <li className=""><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
                            DSGVO). Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                        </li>
                    </ul>
                    <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
                    <ul className="m-elements">
                        <li><strong>Google Fonts (Bezug vom Google Server): </strong>Bezug von Schriften (und Symbolen)
                            zum Zwecke einer technisch sicheren, wartungsfreien und effizienten Nutzung von Schriften
                            und Symbolen im Hinblick auf Aktualität und Ladezeiten, deren einheitliche Darstellung und
                            Berücksichtigung möglicher lizenzrechtlicher Beschränkungen. Dem Anbieter der Schriftarten
                            wird die IP-Adresse des Nutzers mitgeteilt, damit die Schriftarten im Browser des Nutzers
                            zur Verfügung gestellt werden können. Darüber hinaus werden technische Daten
                            (Spracheinstellungen, Bildschirmauflösung, Betriebssystem, verwendete Hardware) übermittelt,
                            die für die Bereitstellung der Schriften in Abhängigkeit von den verwendeten Geräten und der
                            technischen Umgebung notwendig sind. Diese Daten können auf einem Server des Anbieters der
                            Schriftarten in den USA verarbeitet werden - Beim Besuch unseres Onlineangebotes senden die
                            Browser der Nutzer ihre Browser HTTP-Anfragen an die Google Fonts Web API (d. h. eine
                            Softwareschnittstelle für den Abruf der Schriftarten). Die Google Fonts Web API stellt den
                            Nutzern die Cascading Style Sheets (CSS) von Google Fonts und danach die in der CCS
                            angegebenen Schriftarten zur Verfügung. Zu diesen HTTP-Anfragen gehören (1) die vom
                            jeweiligen Nutzer für den Zugriff auf das Internet verwendete IP-Adresse, (2) die
                            angeforderte URL auf dem Google-Server und (3) die HTTP-Header, einschließlich des
                            User-Agents, der die Browser- und Betriebssystemversionen der Websitebesucher beschreibt,
                            sowie die Verweis-URL (d. h. die Webseite, auf der die Google-Schriftart angezeigt werden
                            soll). IP-Adressen werden weder auf Google-Servern protokolliert noch gespeichert und sie
                            werden nicht analysiert. Die Google Fonts Web API protokolliert Details der HTTP-Anfragen
                            (angeforderte URL, User-Agent und Verweis-URL). Der Zugriff auf diese Daten ist
                            eingeschränkt und streng kontrolliert. Die angeforderte URL identifiziert die
                            Schriftfamilien, für die der Nutzer Schriftarten laden möchte. Diese Daten werden
                            protokolliert, damit Google bestimmen kann, wie oft eine bestimmte Schriftfamilie
                            angefordert wird. Bei der Google Fonts Web API muss der User-Agent die Schriftart anpassen,
                            die für den jeweiligen Browsertyp generiert wird. Der User-Agent wird in erster Linie zum
                            Debugging protokolliert und verwendet, um aggregierte Nutzungsstatistiken zu generieren, mit
                            denen die Beliebtheit von Schriftfamilien gemessen wird. Diese zusammengefassten
                            Nutzungsstatistiken werden auf der Seite „Analysen" von Google Fonts veröffentlicht.
                            Schließlich wird die Verweis-URL protokolliert, sodass die Daten für die Wartung der
                            Produktion verwendet und ein aggregierter Bericht zu den Top-Integrationen basierend auf der
                            Anzahl der Schriftartenanfragen generiert werden kann. Google verwendet laut eigener
                            Auskunft keine der von Google Fonts erfassten Informationen, um Profile von Endnutzern zu
                            erstellen oder zielgerichtete Anzeigen zu schalten; <strong>Dienstanbieter:</strong> Google
                            Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland; <span
                                className=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); </span><strong>Website:</strong>
                            <a href="https://fonts.google.com/"
                               >https://fonts.google.com/</a>; <strong>Datenschutzerklärung:</strong> <a
                                href="https://policies.google.com/privacy"
                                >https://policies.google.com/privacy</a>; <strong>Grundlage
                                Drittlandtransfers:</strong> Data Privacy Framework (DPF). <strong>Weitere
                                Informationen:</strong> <a href="https://developers.google.com/fonts/faq/privacy?hl=de"
                                                           >https://developers.google.com/fonts/faq/privacy?hl=de</a>.
                        </li>
                    </ul>
                    <h2 id="m15">Änderung und Aktualisierung</h2><p>Wir bitten Sie, sich regelmäßig über den Inhalt
                    unserer Datenschutzerklärung zu informieren. Wir passen die Datenschutzerklärung an, sobald die
                    Änderungen der von uns durchgeführten Datenverarbeitungen dies erforderlich machen. Wir informieren
                    Sie, sobald durch die Änderungen eine Mitwirkungshandlung Ihrerseits (z. B. Einwilligung) oder eine
                    sonstige individuelle Benachrichtigung erforderlich wird.</p>
                    <p>Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen von Unternehmen und
                        Organisationen angeben, bitten wir zu beachten, dass die Adressen sich über die Zeit ändern
                        können und bitten die Angaben vor Kontaktaufnahme zu prüfen.</p>
                </div>
            </div>
        </section>
    )
}

export default PrivacyPolicy;