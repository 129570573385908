import React from 'react';
import noteIcon from "../../icons/notes-icon.svg";
import phoneIcon from "../../icons/phone-icon.svg";
import containerIcon from "../../icons/container-icon.svg";

function ServicesSection() {
    const services = [
        {
            title: 'Requirements Management',
            icon: noteIcon,
            description:
                'Together, we will record your requirements, define the project framework and create a solid foundation for the software. Good requirements management allows potential problems to be identified at an early stage and avoided.',
        },
        {
            title: 'Mobile App Development',
            icon: phoneIcon,
            description:
                'Native mobile applications are becoming increasingly important alongside web applications. My area of expertise ranges from native Android development with Kotlin and Jetpack Compose to multiplatform applications using Flutter. Further details can be discussed in a meeting.',
        },
        {
            title: 'Deployment & Delivery',
            icon: containerIcon,
            description:
                'You already have a finished application, but you are still missing the last step to deployment? Together we will find the right solution for you and deploy the application according to your wishes.',
        },
    ];

    return (
        <section className="px-4 py-16 bg-white pb-32 md:pb-0">
            <div className="text-center mb-12">
                <h2 className="text-green-600 text-sm font-semibold">Services</h2>
                <h3 className="text-3xl font-bold mt-2">My core Services</h3>
                <p className="text-gray-500 mt-2">
                    Requirements Engineering, Software Development or Deployment?
                </p>
            </div>

            <div className="flex flex-col space-y-12 lg:flex-row lg:space-y-0 lg:space-x-8 lg:justify-center">
                {services.map((service, index) => (
                    <div key={index} className="flex flex-col items-center text-center">
                        <img src={service.icon} alt="container icon" className="w-12 h-12 lg:w-14 lg:h-14"/>
                        <p className="text-black font-bold lg:text-lg w-1/2 pt-10 ">{service.title}</p>
                        <br/>
                        <p className="text-black font-medium xl:w-1/2 text-center">{service.description}</p>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default ServicesSection;