import React from 'react';

const AboutMeSection = () => {
    return (
        <section className="flex flex-col justify-center py-12 px-4 md:px-16 lg:px-24 bg-white">
            {/* Header */}
            <div className="text-center mb-8">
                <h2 className="text-green-900 text-md font-extrabold">About me</h2>
                <h1 className="text-2xl md:text-4xl font-bold mt-2">
                    My journey to professional software development
                </h1>
                <p className="text-gray-600 mt-2">
                    Learn who I am and why I decided to work in the software industry
                </p>
            </div>

            <div className="flex flex-col lg:flex-row justify-center items-center">
                {/* Timeline Section */}
                <div className="lg:w-2/3 relative pl-4 mb-8 lg:mb-0">
                    {/* Vertical Line with Gradient */}
                    <div className="absolute h-full w-1 left-0 top-0 bg-gradient-to-b from-green-900/50 to-green-900"></div>

                    {[
                        { date: '< 2023', description: 'Hobby projects, research and learning' },
                        { date: '< Jul 2023', description: 'Internship in the software industry' },
                        { date: 'Jul 2023', description: 'A-Level with a focus on mathematics and computer science' },
                        { date: 'Since Oct 2023', description: 'Computer Science Student as well as Workingstudent' },
                        { date: 'Apr 2024', description: 'Became a Freelance Software Developer' },
                        { date: 'May 2024', description: 'Founded Pantry Master' },
                        { date: 'Still', description: 'Built large and scalable, industry ready applications' },
                        { date: 'Still', description: 'Lead Mobile Developer and Chief Technology Officer in a large research application' },
                    ].map((item, index) => (
                        <div key={index} className="mb-6">
                            <p className="text-green-700 font-bold">{item.date}</p>
                            <p className="text-gray-800">{item.description}</p>
                        </div>
                    ))}
                </div>

                {/* Stats Section */}
                <div className="flex flex-col lg:flex-row lg:flex-wrap xl:flex-col lg:justify-center gap-4 pt-12">
                    {[
                        {
                            text: '500K++ lines of code',
                            subtext: 'Written in all of my projects',
                            bgColor: 'bg-green-900',
                            textColor: 'text-white',
                        },
                        {
                            text: '0 Projects without CI/CD',
                            subtext: 'To ensure that stakeholders, POs or customers are always up to date',
                            bgColor: 'bg-gray-300',
                            textColor: 'text-gray-900',
                        },
                        {
                            text: 'Grade point average better than one comma five',
                            subtext: 'Achieved in university so far',
                            bgColor: 'bg-gray-300',
                            textColor: 'text-gray-900',
                        },
                    ].map((stat, index) => (
                        <div
                            key={index}
                            className={`${stat.bgColor} p-8 rounded-2xl shadow-lg text-center w-full lg:w-1/2 xl:w-1/2`}
                        >
                            <h3 className={`text-lg font-bold ${stat.textColor}`}>{stat.text}</h3>
                            <p className={`${stat.textColor} mt-2 text-opacity-60`}>{stat.subtext}</p>
                        </div>
                    ))}
                </div>

            </div>
        </section>
    );
};

export default AboutMeSection;
