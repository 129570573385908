import './App.css';
import Content from "./portfolio/components/content/Content";
import NavBar from "./portfolio/components/navbar/NavBar";
import { Routes, Route, HashRouter as Router } from "react-router-dom";
import LegalNotice from "./portfolio/components/legal_notice/LegalNotice";
import PrivacyPolicy from "./portfolio/components/PrivacyPolicy";

function App() {
  return (
      <div className="font-sans relative min-h-screen">
          {/* Entferne diesen fixierten weißen Hintergrund oder passe ihn an */}
          {/* <div className="fixed top-0 left-0 w-full h-full bg-white"></div> */}

          <div className="relative z-10">
              {/* Navbar mit Z-Index und Blur-Effekt */}
              <nav
                  className="fixed top-0 left-0 w-full p-4 z-50">
                  <NavBar/>
              </nav>

              {/* Hauptinhalt */}
              <main className="pt-16 lg:pt-20 w-full">
                  <Router>
                      <Routes>
                          <Route path="/" element={<Content/>}/>
                          <Route path="/legalnotice" element={<LegalNotice/>}/>
                          <Route path="/privacy" element={<PrivacyPolicy/>}/>
                      </Routes>
                  </Router>
              </main>
          </div>
      </div>

  );
}

export default App;
