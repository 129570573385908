import portfolioLogo from '../../icons/portfolio-logo.svg';

const NavBar = () => {
    return (
        <div className="bg-white bg-opacity-20 h-16 lg:h-20 backdrop-filter backdrop-blur-sm shadow-lg rounded-2xl z-50">
            <div className="flex flex-wrap h-full items-center justify-center lg:justify-between px-4 py-2 md:px-8 lg:px-32 lg:py-4">
                <div>
                    <img src={portfolioLogo} alt={"logo"} className={"w-0 lg:w-12 h-0 lg:h-12"} />
                </div>
                <nav className="flex flex-wrap font-bold space-x-4 md:space-x-8 lg:space-x-12">
                    <a
                        href="https://www.linkedin.com/in/maurice-woike/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="relative inline-block text-blue-800 text-sm md:text-base lg:text-lg"
                    >
                        LinkedIn

                        <span
                            className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 flex h-3 w-3">
                          <span
                              className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                          <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                        </span>
                    </a>

                    <a
                        href="https://www.instagram.com/mauricewoike/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-green-900 text-sm md:text-base lg:text-lg"
                    >
                        Instagram
                    </a>
                </nav>
            </div>
        </div>


    );
};

export default NavBar;